<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-md-3">
        <b-list-group>
          <b-list-group-item v-for="item in list" :key="'ci-' + item.id">
            <h5 class="pull-left">{{ item.title }}</h5>
            <b-btn-group class="pull-right">
              <b-button variant="primary" size="sm" @click="edit(item)">EDIT</b-button>
              <b-button variant="danger" size="sm" @click="remove(item)">DELETE</b-button>
            </b-btn-group>

          </b-list-group-item>
        </b-list-group>
        <b-button variant="success" size="sm" @click="addNew">
          <b-icon-plus></b-icon-plus>
          Add new
        </b-button>
      </div>
      <div class="col-md-9">
        <ContentEdit
          v-if="inEdit"
          :item="inEdit"
          @saved="itemSaved"
        ></ContentEdit>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import ContentEdit from '@/components/content/ContentEdit'

export default {
  name: 'ContentList',
  components: { ContentEdit },
  data () {
    return {
      list: null,
      inEdit: null
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.$axios.get(config.baseApiUrl + '/api/content')
        .then((response) => {
          this.list = response.data
        })
    },
    addNew () {
      this.inEdit = {
        id: null,
        title: '',
        content: '',
        company_id: null
      }
    },
    edit (item) {
      this.inEdit = item
    },
    remove (item) {
      if (confirm('Delete item?')) {
        this.inEdit = null
        this.$axios.delete(config.baseApiUrl + '/api/content/' + item.id)
          .then(() => {
            this.getList()
          })
      }
    },
    itemSaved () {
      this.inEdit = null
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
