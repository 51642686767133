<template>
  <div>
    <b-card
      :header="formLabel"
      header-tag="h5"
    >
      <b-card-text>
        <b-form @submit.prevent="save">
          <b-form-group
            id="input-group-1"
            label="Title"
            label-for="input-1"
          >
            <b-form-input v-model="item.title" required></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Text"
            label-for="input-2"
          >
            <ckeditor v-model="item.content"></ckeditor>
          </b-form-group>

          <b-form-group
            label="Meta title"
          >
            <b-form-input v-model="item.meta_title"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Meta keywords"
          >
            <b-form-input v-model="item.meta_keywords"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Meta description"
          >
            <b-form-input v-model="item.meta_desc"></b-form-input>
          </b-form-group>

          <b-form-group
            label="URL"
          >
            <b-form-input v-model="item.url" ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Despecerat"
            label-for="input-3"
          >
            <CompanySelect v-model="item.company_id" :multiple="false"></CompanySelect>
          </b-form-group>
          <b-button type="submit" variant="success" size="sm">
            SAVE
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'
import Vue from 'vue'
import config from '@/config'
import CompanySelect from '@/components/common/CompanySelect'
Vue.use(CKEditor)
export default {
  name: 'ContentEdit',
  components: { CompanySelect },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    formLabel () {
      return this.item.id ? 'Edit: ' + this.item.title : 'Add new'
    },
    slug: function () {
      var slug = this.sanitizeTitle(this.item.title)
      return slug
    }
  },
  watch: {
    slug: function (slug) {
      this.item.url = slug
    }
  },
  methods: {
    sanitizeTitle: function () {
      var slug = ''
      // Change to lower case
      var titleLower = this.item.title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')
      return slug
    },
    save () {
      if (this.item.id) {
        this.$axios.patch(config.baseApiUrl + '/api/content/' + this.item.id, this.item)
          .then(() => {
            this.$emit('saved')
            this.$toasted.success('Saved', {
              duration: 2000
            })
          })
      } else {
        this.$axios.post(config.baseApiUrl + '/api/content', this.item)
          .then(() => {
            this.$emit('saved')
            this.$toasted.success('Saved', {
              duration: 2000
            })
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
